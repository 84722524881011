import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../../Constants';
import { Backdrop, CircularProgress} from '@mui/material';
import {Button, Box, Dialog, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataGrid from '../DataGrid';
import Device from '../Device';
import '../../styles.css';
import cookie from "js-cookie";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import {handleApiErrorMessage} from "../../Helper";
import DeviceAccounts from '../DeviceAccounts';
import RemoveDevice from '../RemoveDevice';


export default function Devices() {
  const { t } = useTranslation();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const defaultSuccessMessage = t("The operation was successfull");
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);

  const [deviceDialogIsOpen, setDeviceDialogIsOpen] = useState(false);

  const initialDevice = {
    'uuid' : '',
    'mac' : '',
    'model' : 0,
    'sn' :'',
    accounts: []
  };
  const [selectedDevice, setSelectedDevice] = useState(initialDevice);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const setSelectedDeviceFromApi = (apiData) => {
    setSelectedDevice({
      'uuid' : apiData.uuid,
      'mac' : apiData.mac,
      'model' : apiData.model.id,
      'sn' : apiData.machineId,
      'accounts' : apiData.accounts
    });
  }

  const columns = [
    { field: 'mac', headerName: t('Mac')},
    { 
      field: 'model', 
      headerName: t('Model'),
      valueGetter: (rowsData) => {
        return t(rowsData.model.name)
      }
    },
    { 
      field: 'status', 
      headerName: t('Status'),
      valueGetter: (rowsData) => {
        return t(rowsData.userStatus)
      }
    },
    { 
      field: 'accounts', 
      headerName: t('Connected Accounts'),
      valueGetter: (rowsData) => {
        return <DeviceAccounts
        device={rowsData}
        afterDeviceAccountsChange={refreshDevicesData}
         />
      }
    },
    { field: 'added_at', headerName: t('Added at')},
    { 
      field: 'edit', 
      headerName: '',
      renderCell: (rowsData, gridFunctions) => {
        return <svg width="16" height="16" viewBox="0 0 16 16" stroke="black" fill="none" xmlns="http://www.w3.org/2000/svg"
          onClick={(e) => {
            setSelectedDeviceFromApi(rowsData);
            setDeviceDialogIsOpen(true);
          }}
        >
        <path d="M0.960938 12.1551V15.1313H3.98174L12.8911 6.3536L9.87029 3.37745L0.960938 12.1551ZM15.2272 4.05204C15.5413 3.74252 15.5413 3.24253 15.2272 2.93301L13.3422 1.07589C13.028 0.76637 12.5205 0.76637 12.2064 1.07589L10.7322 2.52825L13.753 5.50441L15.2272 4.05204Z" fill="white"/>
        </svg>        
      }
    },
    {
      field: 'remove', 
      headerName: '',
      renderCell: (rowsData, gridFunctions) => {
        return <RemoveDevice 
          deviceUuid={rowsData.uuid}
          setShowSuccessMessage={setShowSuccessMessage}
          afterDeviceRemove={refreshDevicesData}
          format="icon"
        />      
      }
    },
  ];

  useEffect(() => {
    if(loading){
      return () => {};
    }
    refreshDevicesData();
  }, []);

  const refreshDevicesData = () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let config = {
      headers: {
        authorization: cookie.get('token')
      }
    }

    axios.post(API_URL + '/YealinkGetDevice',
    {}, 
    config)
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("status") && (data["status"] === API_OK && data.hasOwnProperty("results"))){
          setRows(data.results);

          if(selectedDevice.uuid !== ""){
            const updatedSelectedDevice = data.results.find(
              (o) => {
                return o.uuid === selectedDevice.uuid;
              });
            if(typeof updatedSelectedDevice !== "undefined" && updatedSelectedDevice.uuid !== ""){
              setSelectedDeviceFromApi(updatedSelectedDevice);
            }
          }

          setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
        setLoading(false);
    });
  };

  const handleAddDevice = () => {
    setSelectedDevice(initialDevice);
    setDeviceDialogIsOpen(true);
  };

  return (  
    <div className="content-wrapper">
      {(
        showSuccessMessage===true ?
        <div className="success-message message">
          <div>{successMessage}</div>
          <IconButton onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon />
          </IconButton>
        </div> : ''
      )}
      <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddDevice()}
          className="add-device"
      >
          {t('+ Add Device')}
      </Button>
      <DataGrid 
        className='devices-management'
        rows={rows}
        columns={columns}
      />
      <Dialog open={deviceDialogIsOpen} onClose={() => setDeviceDialogIsOpen(false)}>
        <DialogTitle>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
            <div>{(selectedDevice.uuid === '' ? t('New device') : t('Edit device'))}</div>
            <IconButton onClick={() => setDeviceDialogIsOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Device 
            device={selectedDevice}
            handleDialogClose={() => setDeviceDialogIsOpen(false)} 
            setShowSuccessMessage={setShowSuccessMessage}
            refreshDevicesData={refreshDevicesData}
            />
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
