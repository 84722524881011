import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../Constants';
import '../styles.css';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Select, MenuItem, Backdrop, InputLabel, CircularProgress } from '@mui/material';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../features/errorDialog";
import {handleApiErrorMessage} from "../Helper";
import DeviceAccounts from './DeviceAccounts';
import RemoveDevice from './RemoveDevice';
import {BarcodeScanner} from './BarcodeScanner';
import cookie from "js-cookie";


export default function Device(props) {
  const { t } = useTranslation();
  const [models, setModels] = useState([]);

  const initialDevice = {
    'uuid' : '',
    'mac' : '',
    'model' : '',
    'sn' :''
  };
  const [device, setDevice] = useState(initialDevice);

  const [error, setError] = useState({});

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);
      setLoading(true);

      let  headers = {
        authorization: cookie.get('token')
      }

      axios.post(API_URL + '/YealinkGetModels', 
      {}, 
      {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("status") && (data["status"] === API_OK && data.hasOwnProperty("results"))){
            setModels(data.results);
            setLoading(false);
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
            setLoading(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
          setLoading(false);
      });
  }, []);

  useEffect(() => {
    setDevice(props.device);
  }, []);

  useEffect(() => {
    setDevice(props.device);
  }, [props.device]);

  const allRequiredFieldsFilled = () => {
    let allFilled = true;
    let newError = {};
    if(device.mac === ''){
      newError.mac = true;
      allFilled = false;
    }
    if(device.sn === ''){
      newError.sn = true;
      allFilled = false;
    }
    if(device.model === 0){
      newError.model = true;
      allFilled = false;
    }
    setError(newError);
    return allFilled;
  }

  const handleSubmit = () => {
    if(!allRequiredFieldsFilled()){
      return;
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      authorization: cookie.get('token')
    }

    const postData = {...device};

    axios.post(API_URL + '/YealinkAddDevice', 
    postData, 
    {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("status") && (data["status"] === API_OK)){
          props.refreshDevicesData();
          props.setShowSuccessMessage(true);
          props.handleDialogClose();
          setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
        setLoading(false);
    });
  };

  const getModelsMenuItems = () => {
    let modelsMenuItems = [];
    modelsMenuItems.push(
      <MenuItem key={0} value={0}>{t("Select model")}</MenuItem>
    );

    for(let i = 0; i < models.length; i++){
      modelsMenuItems.push(
        <MenuItem key={models[i].yealink_id} value={models[i].yealink_id}>{models[i].name}</MenuItem>
      );
    }
    return modelsMenuItems;
  }

  const changeLineValue = (e, fieldId) => {
    let value = e.target.value;
    if(fieldId === "mac"){
      value = value.replace(/\W/g, '');
    }
    setDevice({...device, [fieldId] : value});
  }

  return <div className='edit-device'>
      <>
      <div className='fields'>
        {(device.uuid === '' ? 
          <BarcodeScanner
            setMacSn={
              (macValue, snValue) => {
                setDevice({...device, mac : macValue, sn : snValue});
              }
            }
             /> 
          : '')}
        <TextField
            autoFocus
            margin="dense"
            value={device.mac}
            id="mac"
            label={t("Mac (without colon)")}
            type="string"
            fullWidth
            variant="standard"
            disabled={device.uuid !== ''}
            required
            onChange={e => changeLineValue(e, 'mac')}
            error={error.hasOwnProperty('mac')}
            helperText={error.hasOwnProperty('mac') ? t("This is a required field") : ""}
          />
          <TextField
            autoFocus
            margin="dense"
            value={device.sn}
            id="sn"
            label={t("Serial Number")}
            type="string"
            fullWidth
            variant="standard"
            disabled={device.uuid !== ''}
            required
            onChange={e => changeLineValue(e, 'sn')}
            error={error.hasOwnProperty('sn')}
            helperText={error.hasOwnProperty('sn') ? t("This is a required field") : ""}
          />
          <InputLabel id="model-label">{t("Model")}*</InputLabel>
          <Select
            labelId="model-label"
            value={device.model}
            onChange={e => changeLineValue(e, 'model')}
            id="model"
            label={t("Model")}
            disabled={device.uuid !== ''}
            error={error.hasOwnProperty('model')}
            style={{marginBottom: "10px"}}
          >
            {getModelsMenuItems()}
          </Select>
          <DeviceAccounts
            device={device}
            afterDeviceAccountsChange={() => {
              props.refreshDevicesData();
              props.handleDialogClose();
            }}
            />
        </div>
        <div className='actions'>
          {(
            device.uuid !== "" ?
            <RemoveDevice 
              deviceUuid={device.uuid}
              setShowSuccessMessage={props.setShowSuccessMessage}
              afterDeviceRemove={() => {
                props.refreshDevicesData();
                props.handleDialogClose();
              }}
              format="button"
            />  :
            <>
              <Button variant="contained" onClick={handleSubmit} size="large" sx={{ ml: 2 }}>{t('Submit')}</Button>
              <Button variant="outlined" onClick={props.handleDialogClose} size="large">{t('Cancel')}</Button>
            </>
          )}
        </div>
      </>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
  </div>;
}
