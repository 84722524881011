import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../Constants';
import { Backdrop, CircularProgress, Select, MenuItem} from '@mui/material';
import {Button, Dialog} from '@mui/material';
import '../styles.css';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../features/errorDialog";
import {handleApiErrorMessage} from "../Helper";
import cookie from "js-cookie";


export default function DeviceAccounts(props) {
  const { t } = useTranslation();

  const [disconnectDoubleCheckDialogIsOpen, setDisconnectDoubleCheckDialogIsOpen] = useState(false);
  const [showAccountsDropDown, setShowAccountsDropDown] = useState(false);

  const [disconnectAccountId, setDisconnectAccountId] = useState("");
  const [disconnectDeviceUuid, setDisconnectDeviceUuid] = useState("");
  const [currentDeviceUuid, setCurrentDeviceUuid] = useState("");
  const [userAccounts, setUserAccounts] = useState([]);

  const [selectedAccountIdToConnect, setSelectedAccountIdToConnect] = useState("");

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if(props.device.uuid === "" || props.device.uuid === currentDeviceUuid){
      return;
    }
  
    setCurrentDeviceUuid(props.device.uuid);

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      authorization: cookie.get('token')
    }

    axios.post(API_URL + '/GetSipAccountsInCustomer', 
    {}, 
    {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK) && (data.hasOwnProperty("accounts"))){
          let idsToAccounts = data.accounts.reduce((acc, account) => ({...acc,[account.id]:account}),{});
          setUserAccounts(idsToAccounts);

          setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
        setLoading(false);
    });
  }, [props.device.uuid]);

  const formatAccounts = (accounts, deviceUuid) => {
    let formattedAccounts = [];
    for(let i=0; i<accounts.length; i++){
      formattedAccounts.push(
      <div 
        className='device-account' 
        data-id={accounts[i].ym_account_id}
        key={accounts[i].ym_account_id}
      >
        <div className='name'>{accounts[i].ym_account_username}</div>
        <div className='disconnect'
          onClick={
            () => {
              setDisconnectAccountId(accounts[i].ym_account_id);
              setDisconnectDeviceUuid(deviceUuid);
              setDisconnectDoubleCheckDialogIsOpen(true);
            }}
          >x</div>
      </div>)
    }
    return formattedAccounts;
  }

  const handleDisconnectAccount = () => {
    if(disconnectAccountId === ""
      || disconnectDeviceUuid === ""){
      return;
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let config = {
      headers: {
        authorization: cookie.get('token')
      }
    }

    axios.post(API_URL + '/YealinkUnassignAccountFromDevice', 
    {
        ym_account_id: disconnectAccountId,
        device_uuid: disconnectDeviceUuid
    }, 
    config)
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("status") && data["status"] === API_OK){
          setLoading(false);
          props.afterDeviceAccountsChange();
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
        setLoading(false);
    });
  }

  const connectAccountToDevice = () => {
    if(selectedAccountIdToConnect === ""){
      return;
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      authorization: cookie.get('token')
    }

    let postData = {
      ym_account_id: selectedAccountIdToConnect,
      device_uuid: props.device.uuid
    };

    axios.post(API_URL + '/YealinkAssignAccountToDevice', 
    postData, 
    {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("status") && (data["status"] === API_OK)){
          setLoading(false);

          props.afterDeviceAccountsChange();
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
        setLoading(false);
    });
  }

  const handleConnectAccount = () => {
    if(selectedAccountIdToConnect === ""){
      return;
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      authorization: cookie.get('token')
    }

    let postData = {
      ym_account_id: selectedAccountIdToConnect,
      ym_account_username: selectedAccountIdToConnect,
      ym_account_password: userAccounts[selectedAccountIdToConnect].password,
      ym_account_server: "sip.yemot.co.il"
    };

    axios.post(API_URL + '/YealinkAddAccount', 
    postData, 
    {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("status") && (data["status"] === API_OK)){
          setLoading(false);

          connectAccountToDevice(selectedAccountIdToConnect);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
        setLoading(false);
    });
  }

  const getUserAccounts = () => {
    let userAccountsItems = [];

    userAccountsItems.push(<MenuItem key="0" value="0">{t("Select")}</MenuItem>);

    for (let id in userAccounts) {
      if(typeof props.device.accounts.find(
        (o) => {
          return o.ym_account_id === id;
        }) === 'undefined'){
          userAccountsItems.push(<MenuItem key={id} 
            value={id}
          >{id}</MenuItem>);
      }
    }
  
    return userAccountsItems;
  }

  return (  
    <>
      {
        (
          props.device.uuid === "" ? '' : 
          <div className='device-accounts'>
            <div className='title'>{t('Connected Accounts')}</div>
            {(
              (typeof props.device.accounts === "undefined") || props.device.accounts.length === 0 ?
              "--" :
              formatAccounts(props.device.accounts, props.device.uuid)
            )}
            <div 
                className='connect-account'
                onClick={() => setShowAccountsDropDown(true)}
              >{t('+ Connect account')}</div>
            <div 
              className='connect-account-content'>
              {
                showAccountsDropDown ? 
                <><Select
                  labelId="connect-account-label"
                  value={selectedAccountIdToConnect}
                  id="connect-account"
                  label={t("Select account")}
                  onChange={(e) => {
                    setSelectedAccountIdToConnect(e.target.value);
                  }}
                >
                  {getUserAccounts()}
                </Select>
                <Button variant="contained" onClick={handleConnectAccount} size="small">{t('Connect')}</Button></> : ''
              }
            </div>
          </div>
        )
      }
    <Dialog open={disconnectDoubleCheckDialogIsOpen} onClose={() => setDisconnectDoubleCheckDialogIsOpen(false)}>
      <DialogContent
        className='double-check'>
        <div className='question'>{t('Are you sure that you want to disconnect the account from the device?')}</div>
        <div className='actions'>
          <Button variant="contained" 
            onClick={() => {
              handleDisconnectAccount();
              setDisconnectDoubleCheckDialogIsOpen(false);
            }} 
            size="large" sx={{ ml: 2 }}>{t('Submit')}</Button>
          <Button variant="outlined" onClick={() => setDisconnectDoubleCheckDialogIsOpen(false)} size="large">{t('Cancel')}</Button>
        </div>
      </DialogContent>
    </Dialog>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
}
