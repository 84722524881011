import { useEffect, useCallback, useState, useRef } from 'react';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import cookie from "js-cookie";
import { API_URL } from '../Constants';

export const BarcodeScanner = (props) => {
  const { t } = useTranslation();
  const [imgSrc, setImgSrc] = useState(null);
  const [isBarcodeScanInProcess, setIsBarcodeScanInProcess] = useState(false);
  const [isBarcodeScanSuccessful, setIsBarcodeScanSuccessful] = useState(false);
  const [isScanBarcode, setIsScanBarcode] = useState(false);
  const [scanInterval, setScanInterval] = useState(null);
  const videoRef = useRef(null);

  const capture = useCallback(
    () => {
        let canvas = document.createElement('canvas');
        let video = videoRef.current;

        canvas.width = 1920;
        canvas.height = 1080;

        let ctx = canvas.getContext('2d');
        ctx.drawImage( video, 0, 0, canvas.width, canvas.height );

        let image = canvas.toDataURL('image/jpeg');
        setImgSrc(image);
    },
    []
  );

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
}

  const uploadImageToScan = async () => {
    if(imgSrc === null || imgSrc === ""){
        return false;
    }

    // put file into form data
    const data = new FormData();
    const file = dataURLtoFile(imgSrc);

    data.append('barcodes_img', file, file.name);

    let  headers = {
        authorization: cookie.get('token'),
        'Content-Type': 'multipart/form-data'
    };

    // let  headers = {
    //     Authorization: "Bearer 1|WVWbO9uM0C27yJuLWm2l0DRTQGqryuaJHZKWWTWs",
    //     'Content-Type': 'multipart/form-data'
    // };

    // now upload
    const config = {
        headers: headers
    }

    let result = await axios.post(
        API_URL + '/YealinkScanBarcode', 
        data, 
        config
    );
    // let result = await axios.post(
    //     'https://10.100.102.25/api/scanBarcode', 
    //     data, 
    //     config
    // );

    let scannedMac = null;
    let scannedSn = null;
    

    if(result.hasOwnProperty("data")){
        if(result.data.hasOwnProperty("mac")){
            scannedMac = result.data.mac;
        }
    
        if(result.data.hasOwnProperty("sn")){
            scannedSn = result.data.sn;
        }
    }

    if(scannedMac !== null && scannedSn !== null){
        props.setMacSn(scannedMac, scannedSn);

        setIsBarcodeScanInProcess(false);
        setIsBarcodeScanSuccessful(true);
        setIsScanBarcode(false);

        setTimeout(() => {
            setIsBarcodeScanSuccessful(false);
        }, 1000);
    }else{
        setIsBarcodeScanSuccessful(false);
    }

    return true;
  }

  useEffect(() => {
    if(!isScanBarcode && !isBarcodeScanSuccessful){
        return () => {};
    }

    let video = videoRef.current;

    navigator.mediaDevices
    .getUserMedia({ 
        video: {
            facingMode: {
              ideal: "environment"
            }
        },
        audio: false
    })
    .then((stream) => {
      video.srcObject = stream;
      video.play();
    })
    .catch((err) => {
      console.error(`An error occurred: ${err}`);
    });

    return () => {};
  }, [isScanBarcode, isBarcodeScanSuccessful]);

  useEffect(() => {
    if(isScanBarcode !== true){
        if(scanInterval !== null){
            clearInterval(scanInterval);
        }
        return;
    }
    capture();
    
    if(scanInterval === null){
        const newScanInterval = setInterval(() => {
            capture();
        }, 1000);
        setScanInterval(newScanInterval);
    }
    
    return () => {
        clearInterval(scanInterval);
        setScanInterval(null);
    };
  }, [isScanBarcode]);

  useEffect(() => {
    if(isScanBarcode !== true){
        return;
    }
    if(imgSrc === null || imgSrc === ""){
        return;
    }

    uploadImageToScan();
    
    return () => {
    };
  }, [imgSrc]);

  return (
    <>
    <video 
        width="500" height="281"
        id="barcode_video"
        className="webcam"
        style={{display : (isScanBarcode || isBarcodeScanSuccessful ? "block" : "none")}}
        ref={videoRef}
         />
    {(
        isScanBarcode || isBarcodeScanSuccessful ? 
        "" : <Button variant="contained" onClick={() => {
        setIsScanBarcode(true);
        setIsBarcodeScanInProcess(true);
    }}>{t("Scan barcode")}</Button>
    )}
    {(
        isBarcodeScanInProcess ? 
        <div className='progress-wrapper'>
            <div className='progress'>
                <div className='text'>{t("Please move the camera to focus on the barcodes")}</div>
                <div className="ring-wrapper">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        </div>
         :
        (isBarcodeScanSuccessful ? 
        <div className="check-wrapper"> 
            <svg className="animated-check" viewBox="0 0 24 24">
                <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> 
            </svg>
        </div> : '')
    )}
    </>
  );
};
  