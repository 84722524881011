import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../features/user";
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";


export function LoginByToken() {
    const [loginSuccessful, setLoginSuccessful] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        let tokenParam = searchParams.get("token");
        if(tokenParam !== null && (typeof tokenParam !== "undefined")){
            dispatch(login({token : tokenParam}));
            navigate("/devices");
        }
      }, []);

    return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={[
                    {
                      mt: 2
                  }
                ]}
            >
                <div>{(loginSuccessful ? "Login Successful" : "Login Required")}</div>
            </Box>
    );
}