import {React} from 'react';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import Devices from './components/pages/Devices';
import AuthRoute from './components/routes/AuthRoute';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button} from '@mui/material';
import { hideErrorDialog } from "./features/errorDialog";
import { LoginByToken } from './components/pages/LoginByToken';

function App() {
  const user = useSelector((state) => state.user.value);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorDialog = useSelector((state) => state.errorDialog.value);

  const handleErrorDialogClose = () => {
    dispatch(hideErrorDialog());
  };

  return (
    <>
      <Router>
        <Switch> 
          <Route path='/' element={<AuthRoute isLoggedIn={user.isLoggedIn}><Devices /></AuthRoute>} />
          <Route path='/devices' element={<AuthRoute isLoggedIn={user.isLoggedIn}><Devices /></AuthRoute>} />
          <Route path='/login-by-token' element={<LoginByToken />} />
        </Switch>
      </Router>
      <Dialog open={errorDialog.isOpen} onClose={handleErrorDialogClose}>
        <DialogTitle>{t(errorDialog.title)}</DialogTitle>
        <DialogContent>
        <Typography component="div">{t(errorDialog.text)}</Typography>
        </DialogContent>
        <DialogActions> 
          <Button  variant="contained"onClick={handleErrorDialogClose} size="large">{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default App;
