import React, { useState } from 'react';
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../Constants';
import { Backdrop, CircularProgress} from '@mui/material';
import {Button, Dialog} from '@mui/material';
import '../styles.css';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../features/errorDialog";
import {handleApiErrorMessage} from "../Helper";
import cookie from "js-cookie";


export default function RemoveDevice(props) {
  const { t } = useTranslation();

  const [removeDoubleCheckDialogIsOpen, setRemoveDoubleCheckDialogIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleRemoveDevice = () => {
    if(props.deviceUuid === ""){
      return;
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let config = {
      headers: {
        authorization: cookie.get('token')
      }
    }

    axios.post(API_URL + '/YealinkRemoveDevice', 
    {
        uuid: props.deviceUuid
    }, 
    config)
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("status") && data["status"] === API_OK){
          props.setShowSuccessMessage(true);
          setLoading(false);
          props.afterDeviceRemove();
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog);
        setLoading(false);
    });
  }

  return (  
    <>
      {
        (
          props.format === "button" ? 
          <Button variant="contained" onClick={() => setRemoveDoubleCheckDialogIsOpen(true)} size="small">{t('Remove device')}</Button> : 
          <div className='remove' onClick={() => setRemoveDoubleCheckDialogIsOpen(true)}>x</div>
        )
      }
      <Dialog open={removeDoubleCheckDialogIsOpen} onClose={() => setRemoveDoubleCheckDialogIsOpen(false)}>
        <DialogContent
          className='double-check'>
          <div className='question'>{t('Are you sure that you want to remove the device?')}</div>
          <div className='actions'>
            <Button variant="contained" 
              onClick={() => {
                handleRemoveDevice();
                setRemoveDoubleCheckDialogIsOpen(false);
              }} 
              size="large" sx={{ ml: 2 }}>{t('Submit')}</Button>
            <Button variant="outlined" onClick={() => setRemoveDoubleCheckDialogIsOpen(false)} size="large">{t('Cancel')}</Button>
          </div>
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
